import styled from 'styled-components';
import { Layout } from 'antd';
import React from 'react';

const PageFooter = styled(Layout.Footer) <{ style?: React.CSSProperties; }>`
  background: ${({ theme }) => theme.backgroundColor};

  a {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export default PageFooter;
