const TransferTargetChannel = {
  chat: {
    label: 'Chat',
    permission: 'partner.chat',
  },
  form: {
    label: 'Form',
    permission: 'partner.form',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { TransferTargetChannel };
