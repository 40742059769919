import { useSubscription } from '@apollo/client';
import { addResult, removeResult } from '@guuru/graphql-common';
import { useLiveQuery } from '@guuru/react-hooks';
import GET_EXPERT_INBOX from './queries/getInboxChats';
import INBOX_SUBSCRIPTION_ADD from './subscriptions/inboxAddChat';
import INBOX_SUBSCRIPTION_REMOVE from './subscriptions/inboxRemoveChat';
import INBOX_SUBSCRIPTION_UPDATE from './subscriptions/inboxUpdateChat';

export default ((expertId) => {
  const {
    data: {
      expert = {},
    } = {},
    loading: isLoading,
    updateQuery,
  } = useLiveQuery(GET_EXPERT_INBOX, INBOX_SUBSCRIPTION_UPDATE, {
    variables: {
      id: expertId,
    },
  });

  useSubscription(INBOX_SUBSCRIPTION_ADD, {
    variables: { id: expertId },
    onData: addResult({
      list: (prev) => prev?.expert?.inbox,
      element: (result) => result.data.data.inboxAddChat,
      updateQuery,
    }),
  });

  useSubscription(INBOX_SUBSCRIPTION_REMOVE, {
    variables: { id: expertId },
    onData: removeResult({
      list: (prev) => prev?.expert?.inbox,
      elementId: (result) => result.data.data.inboxRemoveChat,
      getId: (value) => value.id,
      updateQuery,
    }),
  });

  const filterChats = (states) => expert?.inbox?.filter(({ status }) => (
    states.includes(status)
  )) || [];
  const newChats = filterChats(['created', 'assigned']);
  const runningChats = filterChats(['open']);

  return { isLoading, newChats, runningChats };
});
