const Automation = {
  MAX_QUESTION_LENGTH: 750,
  PRODUCT_ADVICE_LABEL: 'get_info_product_advise',
  GREETING_LABEL: 'greeting',
  GREETING_ACTION: 'greeting',
  ROUTING_ACTION: 'routing',
  SHOW_ANSWER_ACTION: 'showAnswer',
};

// eslint-disable-next-line import/prefer-default-export
export { Automation };
