import { client } from '@guuru/graphql-web';
import type { User } from 'firebase/auth';
import firebase from './auth';
import UPDATE_PUSH_TOKEN_WEB from './mutations/updatePushTokenWeb';

const { auth } = firebase;

const getCurrentUser = (): User | PromiseLike<User | null> => {
  if (auth.currentUser) {
    return auth.currentUser;
  }

  return new Promise(((resolve) => {
    const unsubscribe = firebase.onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    });
  }));
};

const getCurrentUserId = async () => {
  const user = await getCurrentUser();
  return user?.uid;
};

const getCurrentUserToken = async () => {
  const user = await getCurrentUser();
  return user?.getIdToken();
};

const signInAnonymously = async () => {
  const { user } = await firebase.signInAnonymously(auth);
  return user.uid;
};

const signInWithCustomToken = (customToken: string) => (
  firebase.signInWithCustomToken(auth, customToken)
);

const signIn = async (email: string, password: string) => {
  try {
    return await firebase.signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    throw new Error(err.code);
  }
};

const signOut = async ({ keepPushToken = false } = {}) => {
  const user = await getCurrentUser();
  if (user && !user.isAnonymous && !keepPushToken) {
    await client.mutate({
      mutation: UPDATE_PUSH_TOKEN_WEB,
      variables: {
        id: user.uid,
        pushTokenWeb: null,
      },
    });
  }
  return firebase.signOut(auth);
};

const signUpAnonymously = async () => {
  client.close();
  await firebase.signOut(auth);
  const newUserId = await signInAnonymously();
  return newUserId;
};

export {
  auth,
  getCurrentUser,
  getCurrentUserId,
  getCurrentUserToken,
  signUpAnonymously,
  signIn,
  signInAnonymously,
  signInWithCustomToken,
  signOut,
};
