const QuickActionsOptions = {
  endFlowWithBotMessage: {
    key: 'endFlowWithBotMessage',
    label: 'End flow with bot message',
    help: 'This option will end the flow showing a message to visitor',
    responseLabel: 'Response',
    responseHelp: 'Response message to be given to visitor',
    rules: [{ type: 'string' }],
  },
  requestQuestion: {
    key: 'requestQuestion',
    label: 'Request question',
    help: 'This option will request the custom question added in the field Question below to create a new conversation with an expert',
    responseLabel: 'Question',
    responseHelp: 'Question to be requested to visitor',
    rules: [{ type: 'string' }],
  },
  directLink: {
    key: 'directLink',
    label: 'Direct Link',
    help: 'This option will open a new page in the visitor browser with the link provided below',
    responseLabel: 'URL',
    responseHelp: 'URL to be open in the visitor browser',
    rules: [{ type: 'url', warningOnly: true }],
  },
};

// eslint-disable-next-line import/prefer-default-export
export { QuickActionsOptions };
