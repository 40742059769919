import { useEffect } from 'react';
import {
  useApolloClient,
  useLazyQuery,
  useReactiveVar,
  useSubscription,
} from '@apollo/client';
import { errorCode } from '@guuru/graphql-common';

import type {
  LazyQueryHookOptions,
  SubscriptionHookOptions,
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  ApolloError,
} from '@apollo/client';

// eslint-disable-next-line no-unused-vars
type ErrorHandler = (_: ApolloError) => void;

const makeErrorHandler = (onError?: ErrorHandler) => (err: ApolloError) => {
  if (onError) {
    onError(err);
    return;
  }
  if (!['FORBIDDEN', 'NOT_FOUND'].includes(errorCode(err))) {
    throw err;
  }
};

interface LiveQueryHookOptions<TData, TVariables extends OperationVariables> {
  queryOptions?: LazyQueryHookOptions<TData, TVariables>;
  subscriptionOptions?: SubscriptionHookOptions<TData, TVariables>;
  variables?: TVariables | any;
  skip?: boolean;
  onError?: ErrorHandler;
  // eslint-disable-next-line no-unused-vars
  onData?: (_: TData) => void;
}

const useLiveQuery = function<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
> (
  queryDocument: DocumentNode | TypedDocumentNode<TData, TVariables>,
  subscriptionDocument: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    onError,
    onData,
    skip = false,
    variables = {},
    queryOptions = {},
    subscriptionOptions = {},
  }: LiveQueryHookOptions<TData, TVariables> = {},
) {
  const client: any = useApolloClient();
  const connectedAt = useReactiveVar(client.connectedAt);
  const { skip: skipSubscription } = subscriptionOptions;

  const [execute, query] = useLazyQuery<TData, TVariables>(queryDocument, {
    ...queryOptions,
    variables: {
      ...variables,
      ...(queryOptions.variables ?? {}),
    },
  });

  useSubscription<TData, TVariables>(subscriptionDocument, {
    ...subscriptionOptions,
    skip: subscriptionOptions.skip || skip,
    variables: {
      ...variables,
      ...(subscriptionOptions.variables ?? {}),
    },
  });

  const {
    loading,
    data,
    called,
    refetch,
  } = query;

  useEffect(() => {
    if (!data || !onData) return;
    onData(data);
  }, [data, onData]);

  useEffect(() => {
    if (!skip && (connectedAt || skipSubscription)) {
      const fetchFn = called ? refetch : execute;
      fetchFn().catch(makeErrorHandler(onError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, connectedAt, skipSubscription]);

  return { ...query, loading: !called || loading };
};

export default useLiveQuery;
