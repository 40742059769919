const chatReviewAutoFeedback = {
  expertRating: {
    label: 'Expert Review',
    panel: 'quality',
    required: true,
    help: 'Overall rating for the expert on this chat which is going determine the chat’s review result that can be seen in the conversation view. It should reflect the general evaluation of the Guuru, e.g. willingness to help',
    divider: true,
  },
  expertWritingSkills: {
    sendReviewValue: 0,
    label: 'Expert Writing Skills',
    panel: 'quality',
    help: 'Should reflect writing skills e.g. spelling, grammar, style',
  },
  expertSocialSkills: {
    sendReviewValue: 0,
    label: 'Expert Social Skills',
    panel: 'quality',
    help: 'Should reflect social skills e.g. greetings, friendliness',
  },
  answerRating: {
    sendReviewValue: 0,
    label: 'Answer Quality',
    panel: 'quality',
    help: 'Should reflect the quality of the answer provided by the Guuru. If the answer is poor, the reviewer should leave a respective note with the correct answer in the “Notes” section.',
  },
  claimedEmployee: {
    sendReviewValue: true,
    label: 'Claimed to be an employee',
    panel: 'feedback',
    help: 'Should be used if a Guuru (unconsciously) pretended to be an employee, e.g. writes "we" instead of “I”',
  },
  timeToRespond: {
    sendReviewValue: true,
    label: 'Too much time to respond',
    panel: 'feedback',
    help: 'Should be used  if a Guuru left long waiting times between messages',
  },
  chatAbandoned: {
    sendReviewValue: true,
    label: 'Chat abandoned',
    panel: 'feedback',
    help: 'Should be used to indicate that a Guuru left a chat unexpectedly',
  },
  promotingCompanies: {
    sendReviewValue: true,
    label: 'Promoting other companies',
    panel: 'feedback',
    help: 'Should be used if a Guuru referred to website/offers/products of other companies',
  },
  shouldTransfer: {
    sendReviewValue: true,
    label: 'Should have transferred',
    panel: 'feedback',
    help: 'Should be used if the Guuru should have transferred the chat to an agent or referred to a hotline',
  },
  others: {
    label: 'Other',
    panel: 'feedback',
    help: 'Should be used to point out other issues that should be specifed further in  the “Notes” section',
  },
  transferJustified: {
    sendReviewValue: false,
    help: 'Should be used to indicate that the transfer was done correctly',
  },
  canExpertAnswer: {
    label: 'Guuru can answer',
    panel: 'others',
    help: 'Should be used to indicate that the Guuru should have been able to come up with an answer to the question.  A "no" would for example be a very complex technical inquiry or inquiries that require account access or a complaint.',
  },
  firstCallResolution: {
    label: 'First contact resolution',
    panel: 'others',
    help: 'Should be used to indicate that the visitors issue has been resolved with the chat',
  },
  requiresTrueExpertise: {
    label: 'Request requires true expertise',
    panel: 'others',
    help: 'Should be used to indicate that the answer requires specific domain knowledge or product experience that could not be looked up by a Guuru that is not a user or real expert',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { chatReviewAutoFeedback };
