const ChannelType = {
  chat: 'chat',
  form: 'form',
  community: 'community',
  mail: 'mail',
  facebook: 'facebook',
  salesforce: 'salesforce',
};

// eslint-disable-next-line import/prefer-default-export
export { ChannelType };
