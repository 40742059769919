const DeviceTypes = {
  mobile: {
    name: 'mobile',
  },
  desktop: {
    name: 'desktop',
    minWidthRes: 1280,
  },
  tablet: {
    name: 'tablet',
    minWidthRes: 740,
  },
};

const getDeviceType = (widthRes: number) => {
  if (widthRes >= DeviceTypes.desktop.minWidthRes) {
    return DeviceTypes.desktop.name;
  }
  if (widthRes >= DeviceTypes.tablet.minWidthRes) {
    return DeviceTypes.tablet.name;
  }
  return DeviceTypes.mobile.name;
};

export {
  DeviceTypes,
  getDeviceType,
};
